import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "../../Layout/Hero"
import Button from "../../Elements/Button"
import "./utils/HelpCenter.module.scss"

const HelpCenterBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 800
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const stethoscope = data.stethoscope.childImageSharp.fluid.src

  return (
    <Hero
      backgroundImage={stethoscope}
      className="has-background-cereal-teal mt-1"
    >
      <div className="columns">
        <div className="column is-offset-7 is-offset-4-mobile mt-5-mobile is-7-mobile">
          <h4 className="title">We're here to help</h4>

          <div className="buttons is-narrow are-white mb-1">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate("/help-center")}
            >
              Visit Help Center
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate("/help-center#contact-us")}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </Hero>
  )
}

export default HelpCenterBanner
