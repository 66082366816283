import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

/**
 * An imposing hero banner to showcase something
 * @param {string} color options: primary, success, info, danger, light, dark
 * @param {string} size options: medium, large, fullheight
 * @param {boolean} isCentered
 */

const Hero = ({
  children,
  color,
  size,
  backgroundImage,
  isCentered,
  className,
}) => {
  return (
    <section
      className={classNames(
        `hero is-${color} is-${size}`,
        {
          "has-text-centered": isCentered,
        },
        className
      )}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="hero-body px-0">{children}</div>
    </section>
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
}

export default Hero
