import React, { Fragment, useContext, useEffect } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "./SEO"
import Navbar from "./Navbar"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import { AppContext } from "../../context/AppContext"
import { isDevelopmentEnv } from "../../services/browserCompatibility"
import Notification from "./Notification"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {string} process string options: flexmed, epharmacy
 * @param {} children
 */

const Layout = ({ children, title, subtitle, process, seoTitle, location }) => {
  const { state, dispatch } = useContext(AppContext)

  // const pageTitle = pageContext?.frontmatter?.title

  // useEffect(() => {
  //   if (process && !state[process].agreeToMechanics && !isDevelopmentEnv())
  //     navigate("/")
  //   else if (!process) dispatch({ type: "RESET_DETAILS" })
  // }, [])

  if (process && !state[process].agreeToMechanics && !isDevelopmentEnv())
    return null

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Navbar />
      <Notification isHidden={location?.pathname?.includes("help-center")} />
      {title && (
        <h2 className="has-text-centered mb-0 has-text-primary mx-2-mobile">
          {title}
          <h4 className="subtitle has-text-grey has-text-centered mt-1 is-size-6 mx-2-mobile mb-3">
            {subtitle}
          </h4>
        </h2>
      )}
      <main className="mb-3">{children}</main>
      <HelpCenterBanner />
      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  process: PropTypes.string,
}

export default Layout
