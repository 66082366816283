import React, { useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

const Notification = ({ children, isHidden }) => {
  let dateToday = moment().format("YYYY-MM-DD")
  const [notificationHidden, setNotificationHidden] = useState(
    isHidden || false
  )
  const hasSeenNotificationMessage = hasSeenNotification()

  const { loading, error, data } = useQuery(
    gql`
      query TodaysDate($holiday_date: date!, $domain: jsonb) {
        holiday(
          where: {
            holiday_date: { _eq: $holiday_date }
            domains: { _contains: $domain }
          }
        ) {
          name
          holiday_date
          short_message
        }
      }
    `,
    {
      variables: {
        holiday_date: dateToday,
        domain: process.env.GATSBY_AWS_S3_BUCKET_NAME,
      },
    }
  )

  if (loading) return null
  if (error) console.log(error)

  let notificationMessage =
    data?.holiday === undefined || data?.holiday?.length === 0
      ? null
      : data?.holiday[0]?.short_message

  notificationMessage = (
    <span>
      {notificationMessage || ""} Get assistance through our{" "}
      <a href="/help-center" target="_blank">
        help center
      </a>
      .
    </span>
  )

  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning mb-0",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
